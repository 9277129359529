html,
body,
#root,
.site-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
}

.site-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header, .footer {
  text-align: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 35px;
}

.header {
  border-bottom: solid 2px;
  padding-top: 5px;
  text-align: center;
}

.header__right-item {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.footer {
  border-top: solid 2px;
  bottom: 0;
}

.fa {
  padding: 7px;
  font-size: 20px;
  width: 50px;
  height: 100%;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.title {
  height: 100%;
}

.bg-container {
  position: fixed;
  width: 100%;
  height: 100%;
}

.app-container {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
}

.info-container {
  width: 50%;
  height: 100%;
  position: relative;
  float: right;
  font-family: 'Quattrocento', serif;
  text-align: center;
  padding: 20px;
  overflow: hidden;
}

.info-container h1 {
  font-size: 160px;
  line-height: 110px;
  font-family: 'Oswald', sans-serif;
  position: relative;
  top: calc(50% - 160px);
}

.info-container h1 .jo {
  position: relative;
  left: -50px;
}

.info-container p {
  width: 420px;
  text-align: justify;
  top: calc(50% - -80px);
  right: 0;
  padding: 20px;
  position: absolute;
}

.videos {
  padding: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: calc(50% - 10px);
  margin-bottom: 35px;
  float: left;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container:nth-child(odd) {
  margin-right: 10px;
}

.video-container:nth-child(even) {
  margin-left: 10px;
}

button:focus, 
a:focus {
  outline: none !important;
}


/* colours  */

html,
body,
#root,
.site-container {
  background: #eee;
  color: #111;
}

.header, .footer {
  background-color: #eee;
  border-color: #111;
  color: #111;
}

/* .info-container p {
  background: #eeeeeeaa;
} */

button:focus, 
a:focus {
  background-color: #111;
  color: #eee;
}

.nightmode html,
.nightmode body,
.nightmode #root,
.nightmode .site-container {
  background: #111;
  color: #eee;
}

.nightmode .header, .nightmode .footer {
  background-color: #111;
  border-color: #eee;
  color: #eee;
}


/* .nightmode .info-container p {
  background: #111111aa;
} */

.nightmode button:focus, 
.nightmode a:focus {
  background-color: #eee;
  color: #111;
}


@media (max-width: 860px) { 

  .app-container {
    width: 100%;
  }

  .info-container {
    margin-top: -25vh;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .info-container h1 {
    top: auto;
    margin: 20px;
  }

  .info-container p {
    position: relative;
    width: 100%;
    right: auto;
    top: auto;
    margin-top: 40px;
  }

  .video-container {
    width: 100% 
  }

  .video-container:nth-child(odd) {
    margin-right: 0
  }
  
  .video-container:nth-child(even) {
    margin-left: 0
  }

}

@media (max-width: 450px) { 

  .info-container h1 {
    font-size: 120px;
    line-height: 80px;
  }
  
  .info-container h1 .jo {
    left: -35px;
  }
}

@media (max-width: 340px) { 

  .info-container h1 {
    font-size: 110px;
    line-height: 70px;
  }

}
